.socialContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.poweredWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.iconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
